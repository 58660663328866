import axios from "axios";
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, ModalBody, ModalHeader, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import Banner from "../../../components/utils/Banner";
import { IdiomContext } from "../../../context/createcontext";
import UserService from "../../../services/UserService";
import DocumentsListTable from "../subirDoc/DocumentsListTable";
import { formatDate, formatDefaultDate, formatThousands } from '../utils/Utils';
import DateRangeFootpintFilter from "./DateRangeFootpintFilter";
import DropdownFilter from "./DropdownFootprintFilter";
import ModalLogisticClientReport from "./ModalLogisticClientReport";

function AnswerTable({ idQuestion, idCalculation, addAnswer, activePostButton, status, setNoAnswer, inputs, idQuestionsCurrentBlock }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");

    const [modalDocument, setModalDocument] = useState({ view: false, id: "" });
    const [modalConfirmDuplicate, setModalConfirmDuplicate] = useState({ view: false, answer: {}, id: "" });
    const [itemHasChanged, setItemHasChanged] = useState(null);

    const [answers, setAnswer] = useState({
        data: [],
        isFetching: true
    });

    const [inputsByQuestionIds, setInputsByQuestionIds] = useState([]);
    const [disabledEdition, setDisabledEdition] = useState(false);

    useEffect(() => {
        if (!idQuestionsCurrentBlock || idQuestionsCurrentBlock.length <= 1){
            setInputsByQuestionIds([])
            setDisabledEdition(false)
            return;
        } 
    
        const fetchInputs = async () => {
            try {
                setDisabledEdition(true)

                const requests = idQuestionsCurrentBlock.map(async (element) => {
                    const url = `${process.env.REACT_APP_CH_QUESTIONNAIRE}/calculation/${idCalculation}/questionblock/${idQuestion}?idQuestion=${element.value}`;
    
                    try {
                        const response = await axios.get(url, {
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${UserService.getToken()}`
                            }
                        });
                        return { idQuestion: element.value, inputs: response.data.data.inputs };
                    } catch (error) {
                        console.error(`Error en la pregunta ${element}:`, error);
                        return { idQuestion: element, inputs: [] };
                    }
                });
                setInputsByQuestionIds(await Promise.all(requests))
            } catch (error) {
                console.error("Error general en la carga de preguntas:", error);
            } finally {
                setDisabledEdition(false)
            }
        };
    
        fetchInputs();
    }, [idQuestionsCurrentBlock]);
    
    
    useEffect(() => {
        let finalIdQuestion
        if (idQuestion?.data === undefined) {
            finalIdQuestion = idQuestion;
        } else {
            finalIdQuestion = idQuestion?.data?.id;
        }

        const controller = new AbortController();
        const signal = controller.signal;


        const fetchAnswer = async () => {
            setAnswer({ data: { data: [] }, isFetching: true });
            let lastProcessedIndex = 0;
            let currentPage = 0;
            let remainingOffset = 0;
            const size = 30000;
            let hasMore = true;
            const groupedContent = [];
            const titleMap = new Map();
            while (hasMore) {
                try {
                    const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answers/${finalIdQuestion}?showResults=${status == "FINALIZED" ? "true" : "false"}`
                    try {

                        const response = await axios.get(`${url}`,
                            {
                                params: { currentPage, size, lastProcessedIndex, remainingOffset },
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${UserService.getToken()}`
                                },
                                signal
                            });

                        if (signal.aborted) { return; } // Si la petición fue cancelada, salir


                        const content = response.data?.data?.content || [];
                        const newLastProcessedIndex = response.data?.lastProcessedIndex;
                        const newCurrentPage = response.data?.currentPage;
                        const newRemainingOffset = response.data?.remainingOffset;

                        if (content.length === 0) {
                            hasMore = false;
                        } else {
                            const content = response.data?.data?.content || [];

                            content.forEach(item => {
                                const { title } = item;
                                if (titleMap.has(title)) {
                                    titleMap.get(title).answerBodyOutputDtos.push(...item.answerBodyOutputDtos);
                                } else {
                                    groupedContent.push(item);
                                    titleMap.set(title, item);
                                }
                            });
                            lastProcessedIndex = newLastProcessedIndex;
                            currentPage = newCurrentPage;
                            remainingOffset = newRemainingOffset;
                            setAnswer({ data: { data: groupedContent }, isFetching: true });

                        }
                    } catch (exception) {
                        setAnswer({ data: [], isFetching: false });
                        console.error('Error al realizar la solicitud:', exception);
                        hasMore = false; // Si hay un error, detenerse
                    }
                } catch (exception) {
                    console.error("Error al obtener el reporte", exception);
                    hasMore = false; // Si hay un error, detenerse
                }
            }
            setAnswer({ data: { data: groupedContent }, isFetching: false });
            activePostButton({ data: groupedContent });
            // setEditRowData(undefined);
            // setEditRowDataId(undefined);
        };
        if (idQuestion != undefined) { fetchAnswer(); }

        return () => {
            controller.abort(); // Cancelamos la solicitud si `idQuestion` cambia
        };
    }, [idQuestion, addAnswer])



    const [uploadSpinner, setUploadSpiner] = useState(false)
    const [duplicateSpinner, setDuplicateSpiner] = useState(false)

    const handleDuplicate = (answerDuplicate) => {
        const fetchSavedAnswer = async () => {
            setDuplicateSpiner(true)
            let { creationDate, updateDate, organizationBuildingName, questionId, calculationId, id,
                organizationBuildingId, factorId, factorName, observations,
                registrationDate, billPeriodStartDate, billPeriodFinishDate,
                vehicleFleetId, clientId, questionChild, ...newObj } = answerDuplicate;


            newObj.question = questionId;
            newObj.organizationBuilding = organizationBuildingId;
            newObj.concept = factorId;
            newObj.registration_date = registrationDate;
            newObj.bill_period_start_date = billPeriodStartDate;
            newObj.bill_period_finish_date = billPeriodFinishDate;
            newObj.fleet = vehicleFleetId;
            newObj.client = clientId;

            if (questionChild !== undefined) {
                newObj.question_child = questionChild.map(({ id, ...rest }) => rest);
            }

            if (newObj.zone !== undefined) {
                const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "zone_type").input.options.find(x => x.name == newObj.zone).id;
                newObj.zone_type = inputInfo;
            }

            if (newObj.route !== undefined) {
                const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "route_type").input.options.find(x => x.name == newObj.route).id;
                newObj.route_type = inputInfo;
            }

            if (newObj.gdo !== undefined) {
                const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "gdo").input.options.find(x => x.name == newObj.gdo).id;
                newObj.gdo = inputInfo;
            }
            newObj.observations = "Copia" + (observations !== undefined ? " " + observations : "");

            if (newObj.externalQuestionnaireAnswerId != undefined) {
                delete newObj.externalQuestionnaireAnswerId;
            }

            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer`;
            try {
                const response = await axios.post(url, newObj, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                const idToFind = answerDuplicate.id;
                const newData = answers.data.data.map(entry => {
                    const answerBodyOutputDtos = entry.answerBodyOutputDtos;
                    const index = answerBodyOutputDtos.findIndex(element => element.id === idToFind);
                    const clonedAnswerBodyOutputDtos = [...answerBodyOutputDtos];
                    if (index !== -1) {
                        clonedAnswerBodyOutputDtos.splice(index + 1, 0, response.data.data);
                    }
                    return {
                        ...entry,
                        answerBodyOutputDtos: clonedAnswerBodyOutputDtos
                    };
                });

                setAnswer(prevData => ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        data: newData
                    }
                }));
                setModalConfirmDuplicate({ view: false, answer: {}, id: "" });
                setItemHasChanged(response.data.data.id)
                setTimeout(() => {
                    setItemHasChanged();
                }, 6000);
            } catch (exception) {
                console.error(exception)
                setAnswer({ data: [], isFetching: false });
            }
            setDuplicateSpiner(false)
        }
        fetchSavedAnswer();
    }


    const [file, setFile] = useState(null);
    const [fileMsg, setFileMsg] = useState({ state: "", text: "" })

    const handleFileChange = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        let sanitizedFile = null;

        const maxFileSize = process.env.REACT_APP_CH_CALCULATION;
        if (selectedFile && selectedFile.size > maxFileSize) {
            setFileMsg({ state: "warning", text: t("footPrint.archivoGrande") });
        } else {

            //Extraer la extensión para evitar errores al limpiar el nombre
            let fileNameParts = selectedFile.name.split(".");
            let extension = fileNameParts.pop();
            let baseName = fileNameParts.join(".");

            // Reemplaza los caracteres no deseados
            baseName = baseName.replace(/[^a-zA-Z0-9\-_]/g, "_");

            // Nombre limpio con la extensión
            let cleanedFileName = baseName + "." + extension;

            sanitizedFile = new File([selectedFile], cleanedFileName, {
                type: selectedFile.type,
            });

            setFile(sanitizedFile);
            setFileMsg({ state: "", text: "" });
        }

        const file = sanitizedFile;
        const fileName = sanitizedFile.name;
        let fileOk = true;
        let errorMsg = "";
        if (file) {

            if (/\s/.test(fileName) || /[^\w.-]/.test(fileName)) {
                fileOk = false;
                errorMsg = t("marketplace.errImgNombre")
            }

            const extension = fileName.split(".").pop();
            if (extension !== extension.toLowerCase()) {
                fileOk = false;
                errorMsg = t("marketplace.errImgExtensionMayusculas");
            }

            const fileExtension = fileName.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
            if (!allowedExtensions.includes(fileExtension)) {
                fileOk = false;
                errorMsg = t("footPrint.logo-extension");
            }

            if (fileOk) {
                setFile(sanitizedFile);
                setFileMsg({ state: "", text: "" });
            } else {
                setFileMsg({ state: "warning", text: errorMsg });
                console.error('Error: ' + errorMsg);
                e.target.value = null;
                // setuploadLogoValue([])
            }

        }


    };

    const handleUploadFileAnswer = async (e, idCalculationAnswer, item) => {
        setUploadSpiner(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('files', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/documentation?idQuestionAnswer=${idCalculationAnswer}`,
                formData, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            item.calculationDocumentation = response.data.data;
            modalDocument.item.calculationDocumentation = response.data.data[0];
            setFileMsg({ state: "success", text: t("exceltransactions.fileUpload") });
            setFile(null);

        } catch (exception) {
            console.error(exception);
        }
        setUploadSpiner(false);

    }

    const setDocumentList = (item, documentList) => {
        item.calculationDocumentation = documentList;
        modalDocument.item.calculationDocumentation = documentList[0];
    }

    const cleanMessage = () => {
        setFileMsg({ state: "", text: "" });
    }



    useEffect(() => {
        setModalConfirmDuplicate({ view: false, answer: {}, id: "" });
    }, [modalDocument, addAnswer])

    return (
        <>


            <Modal show={modalDocument.view} size="lg">
                <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                    {t("forestList.NombreDoc")}
                </ModalHeader>
                <ModalBody>

                    <div className="mb-4 flex justify-between items-center gap-1">
                        {modalDocument.item?.questionSubtitle !== undefined && <div>{modalDocument.item?.questionSubtitle}</div>}
                        <div>{modalDocument.item?.organizationBuildingName !== undefined ? modalDocument.item?.organizationBuildingName : ""}</div>
                        <div>{modalDocument.item?.factorName}</div>
                        <div>{modalDocument.item?.quantity && formatThousands(modalDocument.item?.quantity)}</div>
                    </div>

                    {fileMsg.state !== "" && <div className="mb-4"> <Banner type={fileMsg.state} setOpen={true} open={fileMsg.state !== "" ? "open" : ""}>
                        {fileMsg.text}</Banner>
                    </div>}

                    {modalDocument.item?.calculationDocumentation != undefined && <>
                        <DocumentsListTable
                            documents={[modalDocument.item.calculationDocumentation]}
                            enableDelete={status === 'FINALIZED' ? false : true}
                            idCalculation={idCalculation}
                            handleUpdateDocuments={setDocumentList}
                            idCuestionAnswer={modalDocument.item}
                        /></>}
                    {modalDocument.item?.calculationDocumentation === undefined &&
                        <form className=" flex justify-center items-center"
                            onSubmit={(e) => handleUploadFileAnswer(e, modalDocument.item.id, modalDocument.item)}>
                            <input className=''
                                type="file" id="subir_archivo"
                                required
                                onChange={(e) => { cleanMessage(); handleFileChange(e); }}
                                title={t("footPrint.seleccionaArchivo")} />
                            {file && <button className="btn-sm btn-banner-o " type="submit" disabled={uploadSpinner ? "disabled" : ""}>
                                <div className="mr-1">
                                    {!uploadSpinner && <svg xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-file-upload"
                                        width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                        <path d="M12 11v6" />
                                        <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                                    </svg>}
                                    {uploadSpinner && <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true" />}
                                </div>
                                {t("lectorfacturas.subirArchivos")}</button>}
                        </form>}
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className=" text-xs btn-sm btn-cancel" onClick={() => {
                        setModalDocument({ view: false, id: "" });
                        setFileMsg({ state: "", text: "" });
                    }}> {t("footPrint.cerrar")}</button>
                </div>
            </Modal >

            <Modal show={modalConfirmDuplicate.view}>
                <ModalHeader className='sec-neutro-background sec-grey-text font-semibold text-base'>
                    {t("footPrint.duplicar")}
                </ModalHeader>
                <ModalBody>
                    <p className="text-center  text-sm"> {t("footPrint.duplicarSeguro")}</p>
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel text-xs" onClick={() => setModalConfirmDuplicate({ view: false, answer: {} })}>
                        {t("footPrint.cancelar")}
                    </button>
                    <button className="btn-sm btn-green text-xs" disabled={duplicateSpinner} onClick={() => handleDuplicate(modalConfirmDuplicate.answer)}>
                        {duplicateSpinner ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /> : t("footPrint.duplicar")}
                    </button>
                </div>
            </Modal>

            {answers.isFetching && (answers.data.data === undefined || answers.data.data.length === 0) &&
                <div className="flex flex-col justify-center  items-center mt-4">
                    <p>Obteniendo datos...</p>
                    <Spinner animation="border" variant="dark" className="m-4" />
                </div>}

            {(answers.data.data != undefined && answers.data.data.length > 0 ?

                <div className="mt-4 mb-4">

                    <div className="table-responsive">

                        {answers.data.data.map((bloquePreguntas, indexTabla) => {
                            return (
                                <AnswerTableResult
                                    key={indexTabla}
                                    bloquePreguntas={bloquePreguntas}
                                    idCalculation={idCalculation}
                                    answers={answers}
                                    indexTabla={indexTabla}
                                    setNoAnswer={setNoAnswer}
                                    setAnswer={setAnswer}
                                    status={status}
                                    inputs={inputs}
                                    modalDocument={modalDocument}
                                    setModalDocument={setModalDocument}
                                    modalConfirmDuplicate={modalConfirmDuplicate}
                                    setModalConfirmDuplicate={setModalConfirmDuplicate}
                                    idQuestionBlock={idQuestion}
                                    questionIdsCurrentBlock={inputsByQuestionIds}
                                    disabledEdition={disabledEdition}
                                    setItemHasChanged={setItemHasChanged}
                                    itemHasChanged={itemHasChanged}
                                ></AnswerTableResult>

                            )
                        })}


                    </div>
                </div >
                :
                <>
                    {!answers.isFetching && <div className="my-4 footprint-seccion-respuestas">

                        {(status !== 'FINALIZED') && <><p className="text-sm font-bold ">{t("footPrint.noDatosTabla")}</p>
                            <p className="text-sm">{t("footPrint.cubreForm")}.</p></>}
                        {(status == 'FINALIZED') && <><p className="text-sm font-bold text-center">{t("footPrint.noDatos")}</p></>}
                    </div>}
                </>
            )
            }
        </>
    )
}

function AnswerTableResult({ bloquePreguntas, status, idCalculation, answers, indexTabla, setNoAnswer, setAnswer, inputs,
    setModalConfirmDuplicate, modalDocument, modalConfirmDuplicate, setModalDocument, idQuestionBlock, questionIdsCurrentBlock, disabledEdition, setItemHasChanged, itemHasChanged }) {

    const [modalLogisticClientReport, setModalLogisticClientReport] = useState({ view: false, id: idCalculation });
    const [t, i18n] = useTranslation("global");

    const nonEditableFieldsName = ['wtt', 'ttw', 'wtw'];

    const renderTooltipEditar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.noEditExternalAnswer")}
        </Tooltip>
    );
    const renderTooltipEditartrue = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.editar")}
        </Tooltip>
    );
    const renderTooltipdelete = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.eliminar")}
        </Tooltip>
    );
    const renderTooltipDuplicar = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.duplicar")}
        </Tooltip>
    );
    const renderTooltipWarningFile = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.uploadExternalCertification")}
        </Tooltip>
    );

    const [modalConfirmDelete, setModalConfirmDelete] = useState({ view: false, id: "", indexTable: indexTabla });
    const [deleteSpinner, setDeleteSpinner] = useState({ status: false, index: -1 })
    const [deletedAnswer, setDeletedAnswer] = useState({ data: [], isFetching: false });
    const handleDelete = (idCalcAnswer, indexTable) => {

        const originalData = answers.data.data[indexTable]; // Guardar la referencia al objeto original
        const filteredAnswerBodyOutputDtos = originalData.answerBodyOutputDtos.filter((item) => item.id !== idCalcAnswer);
        const updatedData = {
            ...originalData, // Conservar las otras variables intactas
            answerBodyOutputDtos: filteredAnswerBodyOutputDtos, // Actualizar solo answerBodyOutputDtos
        };

        let newArray = answers.data.data[indexTable].answerBodyOutputDtos.filter((item) => item.id != idCalcAnswer);
        const fetchDeleteAnswer = async () => {
            setDeleteSpinner({ status: true, index: idCalcAnswer })
            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer/${idCalcAnswer}`
            try {
                setDeletedAnswer({ deletedAnswer: deletedAnswer.data, isFetching: true });
                const response = await axios.delete(`${url}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        // 'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setDeletedAnswer({ data: response.data, isFetching: false });
                if (response.data.success) {
                    const updatedArray = [...answers.data.data]; // Clonar el array
                    updatedArray[indexTable] = updatedData; // Actualizar el objeto en el índice correspondiente
                    if (updatedArray[0]?.answerBodyOutputDtos.length == 0) {
                        setNoAnswer()
                    }
                    setAnswer({ data: { data: updatedArray }, isFetching: false });
                }

            } catch (exception) {
                setDeletedAnswer({ data: [], isFetching: false });
            }
            setDeleteSpinner({ status: false, index: -1 })
        };
        setModalConfirmDelete({ view: false, id: "", indexTable: 0 })
        fetchDeleteAnswer();

    }

    const [updateSpinner, setUpdateSpinner] = useState(false)
    const [editRowData, setEditRowData] = useState(undefined);
    const [editRowDataId, setEditRowDataId] = useState(undefined);

    const handleUpdateClick = (rowData) => {
        setEditRowData(rowData); // Almacenar los datos actuales de la fila
        setEditRowDataId(rowData.id)
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e;
        const isCheckbox = type === 'checkbox';

        setEditRowData((prevState) => {
            if (name.startsWith("questionChild[")) {
                const [_, index, key] = name.match(/questionChild\[(\d+)\]\.(\w+)/); // Extraer índice y clave
                return {
                    ...prevState,
                    questionChild: prevState.questionChild.map((child, i) =>
                        i === Number(index) ? { ...child, [key]: isCheckbox ? e.target.checked : value } : child
                    ),
                };
            }

            // Manejo general para otros campos
            return {
                ...prevState,
                [name]: isCheckbox ? e.target.checked : value,
            };
        });
    }
    const handleUpdate = (answerUpdate) => {
        const fetchSavedAnswer = async () => {
            setUpdateSpinner(true)
            let { creationDate, updateDate, organizationBuildingName, questionId, calculationId, organizationBuildingId, registrationDate,
                factorId, factorName, gdoId, billPeriodStartDate, billPeriodFinishDate, vehicleFleetId, clientId, questionChild, ...newObj } = answerUpdate;

            newObj.question = questionId;
            newObj.organizationBuilding = organizationBuildingId;
            newObj.concept = factorId;
            newObj.registration_date = registrationDate;
            newObj.gdo = gdoId;
            newObj.bill_period_start_date = billPeriodStartDate;
            newObj.bill_period_finish_date = billPeriodFinishDate;
            newObj.fleet = vehicleFleetId;
            newObj.client = clientId;

            if (questionChild !== undefined) {
                newObj.question_child = questionChild;
            }

            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer`;
            try {
                const response = await axios.patch(url, newObj, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                const idToFind = answerUpdate.id;
                const newData = answers.data.data.map(entry => {
                    const answerBodyOutputDtos = entry.answerBodyOutputDtos;
                    const index = answerBodyOutputDtos.findIndex(element => element.id === idToFind);
                    if (index !== -1) {
                        const clonedAnswerBodyOutputDtos = [...answerBodyOutputDtos];
                        clonedAnswerBodyOutputDtos[index] = response.data.data;
                        return {
                            ...entry,
                            answerBodyOutputDtos: clonedAnswerBodyOutputDtos
                        };
                    }
                    return entry;
                });

                setAnswer(prevData => ({
                    ...prevData,
                    data: {
                        ...prevData.data,
                        data: newData
                    }
                }));
                setModalConfirmDuplicate({ view: false, answer: {}, id: "" });
                setItemHasChanged(response.data.data.id)
                setTimeout(() => {
                    setItemHasChanged(null);
                }, 6000);
            } catch (exception) {
                console.error(exception)
                setAnswer({ data: [], isFetching: false });
            }
            setUpdateSpinner(false)
            setEditRowData(undefined)

        }
        fetchSavedAnswer();


    }

    const [filteredBloquePreguntasLines, setFilteredBloquePreguntasLines] = useState([]);
    const [uniqueOrganizationBuilding, setUniqueOrganizationBuilding] = useState([])
    const [uniqueFactors, setUniqueFactors] = useState([]);
    useEffect(() => {
        setFilteredBloquePreguntasLines(bloquePreguntas.answerBodyOutputDtos);
        const uniqueOrganizationBuildingData = [];
        bloquePreguntas.answerBodyOutputDtos.forEach(item => {
            if (item) {
                const id = item.organizationBuildingId;
                const name = item.organizationBuildingName;

                const existingBuilding = uniqueOrganizationBuildingData.find(building => building.id === id);

                if (!existingBuilding) {
                    uniqueOrganizationBuildingData.push({ id: id, name: name, check: false });
                }
            }
        });
        setUniqueOrganizationBuilding(uniqueOrganizationBuildingData);

        const uniqueFactorData = [];
        bloquePreguntas.answerBodyOutputDtos.forEach(item => {
            if (item) {
                const id = item.factorId;
                const name = item.factorName;
                const existingFactor = uniqueFactorData.find(factor => factor.id === id);

                if (!existingFactor) {
                    uniqueFactorData.push({ id: id, name: name, check: false });
                }
            }
        });
        setUniqueFactors(uniqueFactorData);

    }, [bloquePreguntas]);



    const setApplyOrganizationBuildingsFilter = (data) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            organizationBuildings: data,
        }));
    };
    const setApplyFactorsFilter = (data) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            factors: data,
        }));
    };
    const setApplyDatesFilter = (data) => {
        setFiltros((prevFiltros) => ({
            ...prevFiltros,
            dates: data,
        }));
    };

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    const valueA = a[sortConfig.key];
                    const valueB = b[sortConfig.key];

                    // Handle undefined, null, and empty strings 
                    const aIsEmpty = valueA === undefined || valueA === null || valueA === '';
                    const bIsEmpty = valueB === undefined || valueB === null || valueB === '';

                    if (aIsEmpty && !bIsEmpty) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (bIsEmpty && !aIsEmpty) return sortConfig.direction === 'ascending' ? 1 : -1;
                    if (aIsEmpty && bIsEmpty) return 0; // Both empty, treat as equal

                    // Numerical comparison
                    if (!isNaN(valueA) && !isNaN(valueB)) {
                        return sortConfig.direction === 'ascending' ? valueA - valueB : valueB - valueA;
                    }

                    // Date comparison
                    if (moment(valueA).isValid() && moment(valueB).isValid()) {
                        return sortConfig.direction === 'ascending'
                            ? moment(valueA).diff(moment(valueB))
                            : moment(valueB).diff(moment(valueA));
                    }

                    // String comparison (case-insensitive)
                    return sortConfig.direction === 'ascending'
                        ? valueA.toString().localeCompare(valueB.toString(), undefined, { sensitivity: 'base' })
                        : valueB.toString().localeCompare(valueA.toString(), undefined, { sensitivity: 'base' });
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(filteredBloquePreguntasLines);
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const [filtros, setFiltros] = useState({
        organizationBuildings: [],
        factors: [],
        dates: [],
        text: "",
    });

    const OnFilterText = (filter, filteredData) => {
        return filteredData.filter((v) => {
            const values = Object.values(v || {});
            return values.some(value => {
                if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/)) {
                    const [day, month, year] = filter.split('/').map(Number);
                    const filterDate = (day && month && year) ? new Date(year, month - 1, day) : new Date(NaN);
                    const valueDate = new Date(value);
                    return !isNaN(filterDate) &&
                        filterDate.toLocaleDateString() === valueDate.toLocaleDateString();
                } else if (typeof value === 'string' || typeof value === 'number') {
                    return String(value).toLowerCase().includes(filter.toLowerCase());
                }
                return false;
            }
            );
        });
    };


    const OnFilterOrganizationBuilding = (filter, filteredData) => {
        const filtrosActivos = new Set(filter.filter(f => f.check).map(f => f.id));
        if (filtrosActivos.size > 0) {
            return filteredData.filter(dato => filtrosActivos.has(dato.organizationBuildingId));
        }
        return filteredData;
    };

    const OnFilterFactors = (filter, filteredData) => {
        const filtrosActivos = new Set(filter.filter(f => f.check).map(f => f.id));
        if (filtrosActivos.size > 0) {
            return filteredData.filter(dato => filtrosActivos.has(dato.factorId));
        }
        return filteredData;
    };

    const OnFilterDates = (filter, filteredData) => {
        const fechaInicio = filter[0] ? new Date(filter[0]) : null;
        const fechaFin = filter[1] ? new Date(filter[1]) : null;
        const setTimeToZero = (fecha) => {
            if (fecha) {
                fecha.setHours(0, 0, 0, 0);
            }
            return fecha;
        };
        setTimeToZero(fechaInicio);
        setTimeToZero(fechaFin);

        return filteredData.filter(dato => {
            let arrayFechas = [new Date(dato.registrationDate)];

            if (dato.billPeriodStartDate) {
                arrayFechas.push(new Date(dato.billPeriodStartDate));
            }

            if (dato.billPeriodFinishDate) {
                arrayFechas.push(new Date(dato.billPeriodFinishDate));
            }

            arrayFechas.forEach(setTimeToZero);

            if (fechaInicio && fechaFin && fechaInicio.getTime() === fechaFin.getTime()) {
                return arrayFechas.some((fecha) => fecha.getTime() === fechaInicio.getTime());
            } else if (fechaInicio && fechaFin) {
                return arrayFechas.some((fecha) => fecha >= fechaInicio && fecha <= fechaFin);
            } else if (fechaInicio) {
                return arrayFechas.some((fecha) => fecha >= fechaInicio);
            } else if (fechaFin) {
                return arrayFechas.some((fecha) => fecha <= fechaFin);
            } else {
                return true;
            }
        });
    };
    useEffect(() => {
        const handleInputChange = () => {
            let filteredData = bloquePreguntas.answerBodyOutputDtos;

            if (filtros.text) {
                filteredData = OnFilterText(filtros.text, filteredData);
            }

            if (filtros.organizationBuildings.length > 0) {
                filteredData = OnFilterOrganizationBuilding(filtros.organizationBuildings, filteredData);
            }

            if (filtros.factors.length > 0) {
                filteredData = OnFilterFactors(filtros.factors, filteredData);
            }

            if (filtros.dates.length > 0) {
                filteredData = OnFilterDates(filtros.dates, filteredData);
            }

            setFilteredBloquePreguntasLines(filteredData);
        };
        handleInputChange(bloquePreguntas.questionType);
    }, [filtros]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    // Filtrar y ordenar lineas antes de la paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const [checkedItems, setCheckedItems] = useState([]);
    const [allChecked, setAllChecked] = useState(false);

    useEffect(() => {
        setCurrentPage(1);
    }, [filteredBloquePreguntasLines]);

    useEffect(() => {
        if (checkedItems.length > 0 || allChecked) {
            setCheckedItems([]);
            setAllChecked(false);
        }
    }, [currentPage]);

    const handleCheck = (id) => {
        setCheckedItems((prev) =>
            prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
        );
    };

    const handleCheckAll = () => {
        if (allChecked) {
            setCheckedItems([]);
        } else {
            setCheckedItems(currentItems.filter((item) => item.externalQuestionnaireAnswerId == undefined).map((item) => item.id));
        }
        setAllChecked(!allChecked);
    };

    const InputComponent = React.memo(({ type, value, onChange }) => {
        const handleChange = (e) => {
            const newValue = e.target.value;
            newInputValue = newValue;
        };

        const handleSelectChange = (e) => {
            const newValue = e.value;
            const newLabel = e.label;
            newInputValue = { value: newValue, label: newLabel, hijos: e?.hijos };
        };



        const handleBlur = (e) => {
            onChange(e.target); // Llamar a la función onChange cuando se termina la edición
        };

        const conceptTypes = {
            SIMPLE: "SIMPLE",
            MULTIPLE_FIRST_SELECT : "MULTIPLE_FIRST_SELECT"
        };

        const handleSelectBlur = (conceptType) => {
            if (newInputNameInput === "sedes") {
                onChange({ value: newInputValue.value, name: "organizationBuildingId" });
                onChange({ value: newInputValue.label, name: "organizationBuildingName" });
            } else if (newInputNameInput === "concept") {
                if(conceptType == conceptTypes.SIMPLE) {
                    onChange({ value: newInputValue.value, name: "factorId" });
                    onChange({ value: newInputValue.label, name: "factorName" });
                } else {
                    if (conceptType == conceptTypes.MULTIPLE_FIRST_SELECT) {
                        if (newInputValue?.hijos && newInputValue.hijos.length == 1) {
                            let factor = newInputValue.hijos[0];
                            onChange({ value: factor.id, name: "factorId" });
                            onChange({ value: newInputValue.label + " | " + factor.name, name: "factorName" });
                        } else {
                            onChange({ value: newInputValue.value, name: "factorId" });
                            onChange({ value: newInputValue.label, name: "factorName" });
                        }
                    } else {
                        onChange({ value: newInputValue.value, name: "factorId" });
                        onChange({ 
                            value: editRowData.factorName.includes("|") ?
                                    editRowData.factorName.split("|")[0].trim() + " | " +  newInputValue.label :
                                    editRowData.factorName + " | " +  newInputValue.label ,
                            name: "factorName" });
                    }
                }
            } else if (newInputNameInput === "client") {
                onChange({ value: newInputValue.value, name: "clientId" });
                onChange({ value: newInputValue.label, name: "clientName" });
            } else if (newInputNameInput === "fleet") {
                onChange({ value: newInputValue.value, name: "vehicleFleetId" });
                onChange({ value: newInputValue.label, name: "vehicleFleetIdentifier" });
            } else {
                onChange({ value: newInputValue.value, name: newInputNameInput + "Id" });
                onChange({ value: newInputValue.label, name: newInputNameInput });
            }
        };



        let newInputType = "text";
        let newInputName = type;
        let newInputNameInput = type;

        let newInputValue = value;
        let newInputMin = "";
        let newInputStep = "";

        const questBlockIdsFactorNotEditable = [34, 35, 14, 40];

        if (type.toLowerCase() === "factorname" && questBlockIdsFactorNotEditable.includes(idQuestionBlock)) {
            return editRowData[type];
        }

        if (newInputName.toLowerCase() === "quantity0" &&
            (!editRowData["factorName"].toLowerCase().includes("otro")
                && editRowData["questionId"] !== 11
                && editRowData["questionId"] !== 14)) {
            return editRowData[type] || "";
        }

        if (newInputName.toLowerCase() === "organizationbuildingname") {
            newInputNameInput = "sedes";
        }
        if (newInputName.toLowerCase() === "factorname") {
            newInputNameInput = "concept";
        }
        if (newInputName.toLowerCase() === "clientname") {
            newInputNameInput = "client";
        }
        if (newInputName.toLowerCase() === "vehiclefleetidentifier") {
            newInputNameInput = "fleet";
        }
        if (newInputName.toLowerCase() === "registrationdate") {
            newInputNameInput = "registration_date";
        }
        if (newInputName.toLowerCase() === "billperiodstartdate") {
            newInputNameInput = "bill_period_start_date";
        }
        if (newInputName.toLowerCase() === "billperiodfinishdate") {
            newInputNameInput = "bill_period_finish_date";
        }

        let inputInfo = undefined;

        if (questionIdsCurrentBlock?.length > 1) {
            let selectedQuestionInputs = questionIdsCurrentBlock?.find((element) => element.idQuestion == editRowData?.questionId).inputs;
            inputInfo = selectedQuestionInputs?.find(item => item.input.inputOptions.toLowerCase() === newInputNameInput)?.input;
        } else {
            inputInfo = inputs?.find(item => item.input.inputOptions.toLowerCase() === newInputNameInput)?.input;
        }

        if (inputInfo) {
            const { inputType, options } = inputInfo;

            if (inputType === "INT") {
                newInputType = "number";
                newInputMin = 0;
                newInputStep = 1;
            }

            if (inputType === "DOUBLE") {
                newInputType = "number";
                newInputMin = 0;
                newInputStep = 0.01;
            }

            if (inputType === "DATE") {
                newInputType = "date";
                newInputValue = formatDefaultDate(newInputValue);
            }
            
            if (inputType === "LIST") {
                if (newInputName.toLowerCase() === "organizationbuildingname") {

                    newInputValue = { value: editRowData["organizationBuildingId"], label: newInputValue }

                } else if (newInputName.toLowerCase() === "clientname") {

                    newInputValue = { value: editRowData["clientName"], label: newInputValue }

                } else if (newInputName.toLowerCase() === "vehiclefleetidentifier") {

                    newInputValue = { value: editRowData["vehicleFleetIdentifier"], label: newInputValue }

                } else if (newInputName.toLowerCase() === "factorname" ) {

                    if (inputInfo.options[0]?.combustible != undefined) {

                        let splitedFactorName = newInputValue.split("|");
                        let splitedFactorNameFirst = splitedFactorName[0];
                        let splitedFactorNameSecond = splitedFactorName[1];

                        let optionMainSelect = inputInfo.options.filter(option => {
                            if(splitedFactorNameFirst.trim().includes(option.name)) {
                                return option
                            }
                        }) || [];
                        let optionSecondSelect = optionMainSelect[0]?.combustible.filter(option => {
                            if(splitedFactorNameSecond && splitedFactorNameSecond.trim().includes(option.name)) {
                                return option
                            }
                        }) || [];
                        if (optionSecondSelect.length == 0) {
                            newInputValue = { value: null, label: optionMainSelect[0].name, hijos: optionMainSelect[0].combustible, hijosDefaultValue: null}
                        } else {
                            newInputValue = { value: null, label: optionMainSelect[0].name, hijos: optionMainSelect[0].combustible, hijosDefaultValue: {value: optionSecondSelect[0]?.id, label: optionSecondSelect[0]?.name}}
                        }
                    } else {
                        newInputValue = { value: editRowData["factorId"], label: newInputValue }
                    }
                } else {
                    newInputValue = { value: editRowData[newInputName], label: newInputValue }
                }

                if (newInputName.toLowerCase() === "factorname" && inputInfo.options[0]?.combustible != undefined) {
                    return(
                        <div className="flex flex-col gap-2">


                            <div className="flex flex-col">
                                <span className="text-xs">{inputInfo.title}</span>
                                <Select
                                    name={`${inputInfo.inputOptions}`}
                                    id="FIRST_SELECT_MULTICONCEPT_EDIT"
                                    className='custom-width-md text-xs'
                                    options={options.map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                                    styles={customStyles}
                                    placeholder={`${t('footPrint.selecciona')}...`}
                                    onChange={(e) => handleSelectChange(e)}
                                    onBlur={()=>handleSelectBlur(conceptTypes.MULTIPLE_FIRST_SELECT)}
                                    defaultValue={options.length === 1 ? { label: options[0].name, value: options[0].id } : newInputValue}
                                    required />
                            </div>
                            <div className="flex flex-col">
                                <span className="text-xs">{inputInfo.options[0]?.combustible[0]?.subtitle}</span>
                                <Select
                                    name={`${inputInfo.inputOptions}2`}
                                    id="SECOND_SELECT_MULTICONCEPT_EDIT"
                                    className='custom-width-md text-xs'
                                    options={newInputValue?.hijos?.sort((a, b) => a.name.localeCompare(b.name)).map(combOption => ({ value: combOption.id, label: combOption.name }))}
                                    styles={customStyles}
                                    placeholder={`${t('footPrint.selecciona')}...`}
                                    defaultValue={newInputValue?.hijos.length === 1 ? { label: newInputValue?.hijos[0].name, value: newInputValue?.hijos[0].id } : newInputValue.hijosDefaultValue}
                                    onChange={(e) => handleSelectChange(e)}
                                    onBlur={()=>handleSelectBlur()}
                                    required>
                                </Select>
                            </div>
                        </div>
                    )
                }

                return <Select
                    name={`${inputInfo.inputOptions}`}
                    className='custom-width-md text-xs'
                    options={options.map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                    styles={customStyles}
                    placeholder={`${t('footPrint.selecciona')}...`}
                    onChange={(e) => handleSelectChange(e)}
                    onBlur={()=>handleSelectBlur(conceptTypes.SIMPLE)}
                    defaultValue={options.length === 1 ? { label: options[0].name, value: options[0].id } : newInputValue}
                    required />
            }
        }

        return (
            <input
                className='custom-width-md text-xs'
                style={{ borderColor: 'hsl(0, 0%, 80%)', minWidth: "150px" }}
                type={newInputType}
                name={newInputName}
                defaultValue={newInputValue}
                min={newInputMin}
                step={newInputStep}
                readOnly={false}
                onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleChange(e)}
            />
        );
    });

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
            },
            width: 'auto',
            minWidth: '150px',

        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',

            },
            color: state.isSelected ? 'var(--airco2-green-color)' : provided.color,
            width: '100%',
        }),
        menu: (provided) => ({
            ...provided,
            width: 'auto', // Establecer el ancho automático para que se ajuste al contenido
            minWidth: '150px', // Establecer el ancho mínimo para asegurarse de que no sea demasiado pequeño
            maxWidth: '100vw', // Establecer el ancho máximo para que no exceda el ancho de la ventana
            position: 'absolute', // Establecer la posición absoluta para que el menú desplegable no se vea afectado por otros elementos en la página
        }),
        menuPortal: (provided) => ({
            ...provided,
            zIndex: 9999, // Asegurar que el menú desplegable se renderice por encima de otros elementos
        }),

    };

    const camposASumar = ['kgCO2', 'gch4', 'gn2O', 'totalKgCO2e'];
    const [fieldSums, setFieldSums] = useState([]);
    useEffect(() => {
        const getTotal = () => {
            const fieldSumsItems = {};

            camposASumar.forEach(campo => {
                fieldSumsItems[campo] = Object.values(items).reduce(
                    (acc, curr) => acc + (curr[campo] || 0),
                    0
                );
            })
            setFieldSums(fieldSumsItems)
        };
        getTotal();
    }, [items]);

    const [showMultiEdition, setShowMultiEdition] = useState(false);
    const [multiEditionModal, setMultiEditionModal] = useState({ view: false, changeType: null, changeTypeText: null, selectedQuestionType: null });
    const multipleEditionAllowedInputs = ["SEDES", "REGISTRATION_DATE", "OBSERVATIONS"];
    const [sipnnerMultipleEdition, setSipnnerMultipleEdition] = useState(false);
    const multipleChangeType = {
        EDIT: "EDIT",
        DUPLICATE: "DUPLICATE",
        DELETE: "DELETE"
    }

    const renderTooltipMultipleEdition = (props) => {
        return (
            <Tooltip id="button-tooltip">
                {props.disabled ? t("footPrint.sinRespuestasSeleccionadas") : (props.changeType + " " + t("fpThird.respuestas"))}
            </Tooltip>
        )
    };

    const [formSavingData, setFormSavingData] = useState({});


    const handleMultipleEditionInputChange = (e, inpuOptions) => {
        if (inpuOptions == undefined) {
            const { name, value, type } = e.target;

            setFormSavingData((prevState) => ({
                ...prevState,
                [name.toLowerCase()]: value,
            }));
        } else {
            const { value } = e;

            setFormSavingData((prevState) => ({
                ...prevState,
                [inpuOptions.toLowerCase()]: value,
            }));
        }

    }

    const handleConfirmMultipleEdition = () => {

        const updateMultipleAnswers = async () => {
            setSipnnerMultipleEdition(true);

            let data = {
                changeType: multiEditionModal.changeType,
                calculationAnswerIds: checkedItems,
            };

            if (multiEditionModal.changeType != multipleChangeType.DELETE) {
                let answerInputDtoArray = [];

                checkedItems.forEach(element => {

                    let selectedAnswer = currentItems.find(x => x.id == element);

                    let { creationDate, updateDate, organizationBuildingName, questionId, calculationId, id, organizationBuildingId, factorId, factorName, gdoId, observations, registrationDate, billPeriodStartDate, billPeriodFinishDate, vehicleFleetId, clientId, questionChild, ...newObj } = selectedAnswer;

                    newObj.question = questionId;
                    newObj.organizationBuilding = organizationBuildingId;
                    newObj.concept = factorId;
                    newObj.registration_date = registrationDate;
                    newObj.bill_period_start_date = billPeriodStartDate;
                    newObj.bill_period_finish_date = billPeriodFinishDate;
                    newObj.gdo = gdoId;
                    newObj.fleet = vehicleFleetId;
                    newObj.client = clientId;



                    if (multiEditionModal.changeType == multipleChangeType.DUPLICATE) {

                        if (newObj.gdo !== undefined) {
                            const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "gdo").input.options.find(x => x.name == newObj.gdo).id;
                            newObj.gdo = inputInfo;
                        }
                        newObj.observations = "Copia" + (observations !== undefined ? " " + observations : "");

                        if (newObj.externalQuestionnaireAnswerId != undefined) {
                            delete newObj.externalQuestionnaireAnswerId;
                        }

                        if (questionChild !== undefined) {
                            newObj.question_child = questionChild.map(({ id, ...rest }) => rest);
                        }

                        if (newObj.zone !== undefined) {
                            const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "zone_type").input.options.find(x => x.name == newObj.zone).id;
                            newObj.zone_type = inputInfo;
                        }

                        if (newObj.route !== undefined) {
                            const inputInfo = inputs.find(item => item.input.inputOptions.toLowerCase() === "route_type").input.options.find(x => x.name == newObj.route).id;
                            newObj.route_type = inputInfo;
                        }


                    } else if (multiEditionModal.changeType == multipleChangeType.EDIT) {

                        newObj.id = id;
                        newObj.organizationBuilding = formSavingData.sedes != undefined ? formSavingData.sedes : organizationBuildingId;
                        newObj.registration_date = formSavingData.registration_date != undefined ? formSavingData.registration_date : registrationDate;
                        newObj.observations = formSavingData.observations != undefined ? formSavingData.observations : observations;

                        if (questionChild !== undefined) {
                            newObj.question_child = questionChild;
                        }
                    }

                    answerInputDtoArray.push(newObj)
                });

                data.answerInputDtoList = answerInputDtoArray;
            }

            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answers`;
            try {
                const response = await axios.patch(url, data, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                let responseData = response.data.data;

                if (response.data.success) {

                    const newData = answers.data.data.map(entry => {

                        if (entry.questionType == multiEditionModal.selectedQuestionType) {
                            const answerBodyOutputDtos = entry.answerBodyOutputDtos;
                            let clonedAnswerBodyOutputDtos = [...answerBodyOutputDtos];

                            switch (multiEditionModal.changeType) {
                                case multipleChangeType.EDIT:
                                    checkedItems.forEach(idToFind => {
                                        const index = answerBodyOutputDtos.findIndex(element => element.id === idToFind);
                                        if (index !== -1) {
                                            clonedAnswerBodyOutputDtos[index] = responseData.find(updatedAnswer => updatedAnswer.id == idToFind);
                                        }
                                    });
                                    break;
                                case multipleChangeType.DUPLICATE:
                                    responseData.forEach(duplicatedAnswer => {
                                        clonedAnswerBodyOutputDtos.push(duplicatedAnswer);
                                    });
                                    break;
                                case multipleChangeType.DELETE:
                                    clonedAnswerBodyOutputDtos = clonedAnswerBodyOutputDtos.filter(deletedAnswer => !checkedItems.includes(deletedAnswer.id));
                                    if (clonedAnswerBodyOutputDtos.length == 0) {
                                        setNoAnswer()
                                    }
                                    break;
                            }

                            return {
                                ...entry,
                                answerBodyOutputDtos: clonedAnswerBodyOutputDtos
                            };

                        } else {
                            return entry;
                        }
                    });


                    setAnswer(prevData => ({
                        ...prevData,
                        data: {
                            ...prevData.data,
                            data: newData
                        }
                    }));
                }

            } catch (exception) {
                console.error(exception)
            } finally {
                setSipnnerMultipleEdition(false);
                setMultiEditionModal({ view: false, changeType: null, changeTypeText: null, selectedQuestionType: null });
                setFormSavingData({});
                handleCheckAll();
            }

        }
        if (multiEditionModal.changeType == multipleChangeType.EDIT) {

            let confirmation = window.confirm(t("footPrint.alertConfirmarEditar1") + checkedItems.length + t("footPrint.alertConfirmarEditar2"));

            if (confirmation) {
                updateMultipleAnswers();
            } else {
                setMultiEditionModal({ view: false, changeType: null, changeTypeText: null, selectedQuestionType: null });
                setFormSavingData({});
                handleCheckAll();

            }
        } else {
            updateMultipleAnswers();

        }
    }
    const [clientsList, setClientsList] = useState(undefined);
    useEffect(() => {
        const getClientsList = () => {
            setClientsList(
                Array.from(
                    new Set(items.filter(obj => obj.clientId).map(obj => obj.clientId))
                ).map(clientId => ({
                    id: clientId,
                    name: items.find(obj => obj.clientId === clientId)?.clientName
                }))
            );
        };
        getClientsList();
    }, [items]);



    return (
        <div className={`${bloquePreguntas.length > 1 ? "" : ""} mb-4`}>

            <Modal show={modalConfirmDelete.view}>
                <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                    {t("footPrint.eliminar")}
                </ModalHeader>
                <ModalBody>
                    <p className="text-center text-sm"> {t("footPrint.eliminarSeguro")}</p>
                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel text-xs" onClick={() => setModalConfirmDelete({ view: false, id: "", indexTable: indexTabla })}> {t("footPrint.cancelar")}</button>
                    <button className="btn-sm btn-green text-xs" onClick={() => handleDelete(modalConfirmDelete.id, modalConfirmDelete.indexTable)}> {t("footPrint.eliminar")}</button>
                </div>
            </Modal>

            <Modal show={multiEditionModal.view}>
                <ModalHeader className='sec-neutro-background sec-grey-text text-base font-semibold'>
                    {multiEditionModal.changeTypeText + " " + t("fpThird.respuestas")}
                </ModalHeader>
                <ModalBody>
                    {multiEditionModal.changeType != multipleChangeType.EDIT ?
                        <div>
                            <p className="text-center text-sm">{t("footPrint.estasSeguro") + multiEditionModal?.changeTypeText?.toLowerCase() + " " + t("footPrint.las") + " " + checkedItems.length + " " + t("footPrint.respuestasSeleccionadas")}</p>
                        </div>
                        :
                        <div>
                            <p className="text-center text-sm">{t("footPrint.edicionMultipleInstruccion")}</p>
                        </div>
                    }

                    {multiEditionModal.selectedQuestionType != null && multiEditionModal.changeType == multipleChangeType.EDIT &&

                        <div className={`m-4 text-xs`} style={{ display: 'block' }}>
                            {inputs?.filter((input) => multipleEditionAllowedInputs.includes(input.input.inputOptions)).map((inputObj, i) => {
                                const input = inputObj.input;
                                let options = input.options;

                                return (
                                    <div>

                                        <label className={`custom-width-md flex items-center m-0`} >
                                            <span>{input.title}</span>
                                        </label>

                                        {input.inputOptions == "SEDES" &&
                                            <div className="mb-2">
                                                <Select
                                                    key={`input_${input.inputOptions}`}
                                                    name={`${input.inputOptions}`}
                                                    className='custom-width-md text-xs '
                                                    options={options.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({ label: option.name, value: option.id }))}
                                                    styles={customStyles}
                                                    placeholder={`${t('footPrint.selecciona')}...`}
                                                    onChange={(e) => handleMultipleEditionInputChange(e, input.inputOptions)}
                                                    defaultValue={""}
                                                    required={false} />
                                            </div>
                                        }
                                        {input.inputOptions == "OBSERVATIONS" &&
                                            <div className="mb-2">
                                                <input
                                                    key={`input_${input.inputOptions}`}
                                                    name={`${input.inputOptions}`}
                                                    onChange={(e) => handleMultipleEditionInputChange(e)}
                                                    defaultValue={""}
                                                    className='custom-width-md  text-xs'
                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                    type="text"
                                                    required={false}
                                                />
                                            </div>
                                        }
                                        {input.inputOptions == "REGISTRATION_DATE" &&
                                            <div className="mb-2">
                                                <input
                                                    key={`input_${input.inputOptions}`}
                                                    name={`${input.inputOptions}`}
                                                    onChange={(e) => handleMultipleEditionInputChange(e)}
                                                    defaultValue={""}
                                                    className='custom-width-md  text-xs'
                                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                    type="date"
                                                    required={false}
                                                />
                                            </div>
                                        }
                                    </div>
                                )
                            })
                            }
                        </div>
                    }

                </ModalBody>
                <div className="flex justify-center space-x-4 mb-4">
                    <button className="btn-sm btn-cancel text-xs" onClick={() => { setMultiEditionModal({ view: false, changeType: null, changeTypeText: null, selectedQuestionType: null }), setFormSavingData({}) }}> {t("footPrint.cancelar")}</button>
                    <button className="btn-sm btn-green text-xs flex items-center gap-2" disabled={sipnnerMultipleEdition} onClick={() => handleConfirmMultipleEdition()}>
                        <span>{t("footPrint.confirmar")}</span>
                        {sipnnerMultipleEdition &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                variant="light" />}
                    </button>
                </div>
            </Modal>


            <ModalLogisticClientReport
                modal={modalLogisticClientReport}
                setModal={setModalLogisticClientReport}
                clientList={clientsList}
                questionType={bloquePreguntas.questionType}
            />

            <div className="flex justify-between items-center">
                <div className="font-bold">
                    {bloquePreguntas.title}
                    <span className="text-xs ml-2">
                        {bloquePreguntas.subtitle}</span>
                </div>
                {clientsList && clientsList.length > 0 && <div>
                    <button className="btn-sm btn-cancel text-xs" onClick={() => setModalLogisticClientReport({ view: true, id: idCalculation })}> {t("footPrint.report.report")}</button>
                </div>}

            </div>


            {!answers.isFetching &&
                <div className="flex  flex-wrap md:flex-nowrap justify-between mt-1 gap-1">
                    {currentItems.filter((item) => item.externalQuestionnaireAnswerId == undefined).length > 0 ?
                        <div className="flex items-end gap-2">
                            <div>
                                <button type="button" className=" flex gap-2 text-xs sec-grey-text btn-sm btn-cancel " onClick={() => setShowMultiEdition(!showMultiEdition)}>
                                    <span>{t("footPrint.edicionMultiple")}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokelinejoin="round" width={20} height={20} strokeWidth={2}>
                                        <path stroke="none" d="M0 0h24v24H0z"></path>
                                        <path d="M7 9.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                        <path d="M4.012 16.737a2 2 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                                        {showMultiEdition && <path d="M11 14l2 2l4 -4"></path>}
                                    </svg>
                                </button>
                            </div>

                            {showMultiEdition && (
                                <div className="flex items-emd gap-1">
                                    <div>
                                        <button type="button" style={{ padding: '4px', borderRadius: '0.125rem' }} disabled={checkedItems.length == 0}
                                            onClick={() => setMultiEditionModal({ view: true, changeType: multipleChangeType.EDIT, changeTypeText: t("footPrint.editar"), selectedQuestionType: bloquePreguntas.questionType },)}
                                            className="flex items-center gap-2 text-xs text-sec-grey btn-cancel" >
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipMultipleEdition({ disabled: checkedItems.length == 0, changeType: t("footPrint.editar") })}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                    <path d="M16 5l3 3" />
                                                </svg>
                                            </OverlayTrigger>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" style={{ padding: '4px', borderRadius: '0.125rem' }} disabled={checkedItems.length == 0}
                                            onClick={() => setMultiEditionModal({ view: true, changeType: multipleChangeType.DUPLICATE, changeTypeText: t("footPrint.duplicar"), selectedQuestionType: bloquePreguntas.questionType })}
                                            className="flex items-center gap-2 text-xs text-sec-grey btn-cancel">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipMultipleEdition({ disabled: checkedItems.length == 0, changeType: t("footPrint.duplicar") })}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                </svg>
                                            </OverlayTrigger>
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" style={{ padding: '4px', borderRadius: '0.125rem' }} disabled={checkedItems.length == 0}
                                            onClick={() => setMultiEditionModal({ view: true, changeType: multipleChangeType.DELETE, changeTypeText: t("footPrint.eliminar"), selectedQuestionType: bloquePreguntas.questionType })}
                                            className="flex items-center gap-2 text-xs text-sec-grey btn-cancel">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltipMultipleEdition({ disabled: checkedItems.length == 0, changeType: t("footPrint.eliminar") })}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round" >
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M4 7l16 0" />
                                                    <path d="M10 11l0 6" />
                                                    <path d="M14 11l0 6" />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                            </OverlayTrigger>
                                        </button>
                                    </div>
                                </div>
                            )}

                        </div> : <div />
                    }


                    <div className="flex flex-wrap md:justify-end gap-1" style={{ maxWidth: '420px' }}>
                        <DropdownFilter
                            uniqueValues={uniqueOrganizationBuilding}
                            applyFilter={setApplyOrganizationBuildingsFilter}
                            nombreFiltro={t("footPrint.sede")}
                        />

                        <DropdownFilter
                            uniqueValues={uniqueFactors}
                            applyFilter={setApplyFactorsFilter}
                            nombreFiltro={t("footPrint.factor")}
                        />

                        <DateRangeFootpintFilter
                            applyFilter={setApplyDatesFilter} />

                        <div className="search-container">
                            <input value={filtros.text}
                                className=' text-xs'
                                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                onChange={(e) =>
                                    setFiltros((prevFiltros) => ({
                                        ...prevFiltros,
                                        text: e.target.value,
                                    }))
                                }
                                placeholder={t("footPrint.wordSearch")}
                                type="text"></input>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                <path d="M21 21l-6 -6" />
                            </svg>
                        </div>
                    </div>
                </div>}
            {answers.isFetching &&
                <div className="flex  justify-center  items-center">
                    <p>Cargando respuestas...</p>
                    <Spinner animation="border" variant="dark" className="ml-2" size="sm" />
                </div>}

            <div className="container-simple-table mt-2">
                <table className="simple-table">

                    <thead className="text-center text-white sec-green-background">
                        <tr key={bloquePreguntas.id}>
                            {showMultiEdition && (
                                <th>
                                    <input type="checkbox" onChange={handleCheckAll} checked={allChecked} />
                                </th>
                            )}
                            {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                                <th key={key} >
                                    <button type="button" onClick={() => requestSort(key)}
                                        className={`${getClassNamesFor(key)} `}>
                                        {bloquePreguntas.answerHeaderOutputDto[key]}
                                    </button>
                                </th>
                            ))}
                            {(status !== 'FINALIZED') && <>
                                <th>{t("footPrint.subirArchivos")}</th>
                                <th>{t("fpThird.acciones")}</th>
                            </>
                            }
                        </tr>
                    </thead>

                    <tbody className="text-center ">
                        {currentItems.map((item, index) => (
                            <>
                                <tr key={index}
                                    className={`${modalDocument?.view && modalDocument?.item.id == item.id || modalConfirmDelete?.view && modalConfirmDelete?.id == item?.id || modalConfirmDuplicate?.view && modalConfirmDuplicate?.id === item?.id ? "sec-green-background-tr" : ''}
                                 ${itemHasChanged === item?.id && "sec-green-background-tr2"}`}>

                                    {(status !== 'FINALIZED' && showMultiEdition) &&
                                        <td>
                                            {item.externalQuestionnaireAnswerId == undefined &&
                                                <input type="checkbox" onChange={() => handleCheck(item.id)} checked={checkedItems.includes(item.id)} />}
                                        </td>
                                    }

                                    {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                                        <td key={key}>
                                            {editRowData !== undefined && editRowDataId === item.id ?
                                                <>
                                                    {/* <span className="text-xs">  {bloquePreguntas.answerHeaderOutputDto[key]}</span> */}
                                                    {!nonEditableFieldsName.includes(key) &&
                                                        <InputComponent type={key} value={editRowData[key]} onChange={handleInputChange}></InputComponent>}
                                                </>
                                                :
                                                (
                                                    item[key] === undefined || item[key] === "" ? "" :
                                                        (isNaN(item[key]) ?
                                                            ((key === "registrationDate" || key === "billPeriodStartDate" || key === "billPeriodFinishDate") ? formatDate(item[key]) : item[key])
                                                            :
                                                            (key === "identifier" ? item[key] : formatThousands(item[key]))
                                                        )
                                                )
                                            }
                                        </td>
                                    ))}

                                    {(status !== 'FINALIZED') && <>
                                        <td >
                                            {editRowData?.id !== item.id && <>

                                                <button onClick={() => setModalDocument({ view: true, item: item })}
                                                    className="relative">
                                                    {item.calculationDocumentation === undefined ? <>
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            className="icon icon-tabler icon-tabler-clipboard-plus" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                                            <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                            <path d="M10 14h4" />
                                                            <path d="M12 12v4" />
                                                        </svg>
                                                        {bloquePreguntas.questionType === "EXTERNAL_CALCULATION" &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltipWarningFile}>
                                                                <span className="absolute rounded-full bg-orange " style={{ left: "15px", bottom: "12px", padding: "4px" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="13" height="13" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff " fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M12 9v4" />
                                                                        <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                                                        <path d="M12 16h.01" />
                                                                    </svg>
                                                                </span>
                                                            </OverlayTrigger>
                                                        }
                                                    </>
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            className="icon icon-tabler icon-tabler-file-check" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                            <path d="M9 15l2 2l4 -4" />
                                                        </svg>}
                                                </button>



                                            </>
                                            }
                                        </td>


                                        <td className="">
                                            <div className="grid grid-flow-col gap-1">
                                                {(status !== 'FINALIZED' && editRowData?.id !== item.id) &&
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipDuplicar}
                                                    >
                                                        <button onClick={() => setModalConfirmDuplicate({ view: true, answer: item, id: item.id })}>

                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                                            </svg>
                                                        </button>

                                                    </OverlayTrigger>}
                                                {(status !== 'FINALIZED' && editRowData === undefined) &&
                                                    <button onClick={() => handleUpdateClick(item)} disabled={(item.externalQuestionnaireAnswerId != undefined ? true : false) || disabledEdition } >
                                                        {item.externalQuestionnaireAnswerId != undefined ?
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltipEditar}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#64748b" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                    <path d="M16 5l3 3" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                            :
                                                            <OverlayTrigger
                                                                placement="top"
                                                                delay={{ show: 250, hide: 400 }}
                                                                overlay={renderTooltipEditartrue}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                    <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                    <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                    <path d="M16 5l3 3" />
                                                                </svg>
                                                            </OverlayTrigger>
                                                        }
                                                    </button>
                                                }
                                                {updateSpinner && editRowData?.id === item.id && <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true" />}
                                                {!updateSpinner && editRowData !== undefined && editRowData.id === item.id && <>

                                                    <button onClick={() => handleUpdate(editRowData)}>

                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-device-floppy" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
                                                            <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                                            <path d="M14 4l0 4l-6 0l0 -4" />
                                                        </svg>
                                                    </button>

                                                    <button onClick={() => {
                                                        setEditRowData(undefined);
                                                        setEditRowDataId(undefined)
                                                    }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M18 6l-12 12" />
                                                            <path d="M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                </>}
                                                {editRowData?.id !== item.id && <>
                                                    {(!deleteSpinner.status || deleteSpinner.index !== item.id) &&
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 250, hide: 400 }}
                                                            overlay={renderTooltipdelete}
                                                        >
                                                            <button onClick={() => setModalConfirmDelete({ view: true, id: item.id, indexTable: indexTabla })}>
                                                                {item.externalQuestionnaireAnswerId ?

                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-corner-up-left-double" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M19 18v-6a3 3 0 0 0 -3 -3h-7" />
                                                                        <path d="M13 13l-4 -4l4 -4m-5 8l-4 -4l4 -4" />
                                                                    </svg>

                                                                    :

                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <path d="M4 7l16 0" />
                                                                        <path d="M10 11l0 6" />
                                                                        <path d="M14 11l0 6" />
                                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                    </svg>
                                                                }
                                                            </button>
                                                        </OverlayTrigger>


                                                    }
                                                    {deleteSpinner.status && deleteSpinner.index === item.id && <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true" />}
                                                </>}
                                            </div>
                                        </td>
                                    </>
                                    }
                                </tr >
                                {item?.questionChild != undefined && item?.questionChild.length > 0 &&
                                    <>
                                        {item.questionChild.map((itemQuestion, questionChildIndex) =>
                                            <tr>
                                                {showMultiEdition && <td></td>}
                                                {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key) => (
                                                    <td key={key} className="text-xs text-airco2-general-grey">
                                                        {editRowData !== undefined && editRowDataId === item.id ?
                                                            <>
                                                                {(itemQuestion[key] && !nonEditableFieldsName.includes(key)) &&
                                                                    <InputComponent type={'questionChild[' + questionChildIndex + '].' + key} value={editRowData['questionChild'][questionChildIndex][key]} onChange={handleInputChange}></InputComponent>}
                                                            </>
                                                            :
                                                            (
                                                                itemQuestion[key] === undefined || itemQuestion[key] === "" ? "" :
                                                                    (isNaN(itemQuestion[key]) ?
                                                                        (key === "registrationDate" ? formatDate(itemQuestion[key]) : itemQuestion[key])
                                                                        :
                                                                        (key === "identifier" ? itemQuestion[key] : formatThousands(itemQuestion[key]))
                                                                    )
                                                            )
                                                        }
                                                    </td>

                                                ))}
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                        }
                                    </>}

                                {(status === 'FINALIZED' || UserService.hasRole(['admin'])) && (item?.associatedAnswerOutputDto != undefined && item?.associatedAnswerOutputDto.length > 0) &&
                                    <>
                                        <tr>
                                            <td colSpan={Object.keys(bloquePreguntas.answerHeaderOutputDto).length}>
                                                <table className=" text-sm table-auto w-full divide-y divide-gray-200 table-associated-footprint mb-4">
                                                    <thead style={{ backgroundColor: "#d9d9d9" }}>
                                                        <th>Dato de consumo</th>
                                                        <th>gCH₄</th>
                                                        <th>gN₂O</th>
                                                        <th>kgCO₂</th>
                                                        <th>Total KgCO₂e</th>
                                                    </thead>
                                                    <tbody >
                                                        {item.associatedAnswerOutputDto.map((x) =>
                                                            <>
                                                                <tr style={{ backgroundColor: "#e5e1d7" }} key={x.id}>
                                                                    <td>{x.factorName}</td>
                                                                    <td>{x.gch4 != undefined ? formatThousands(x.gch4) : ""}</td>
                                                                    <td>{x.gn2O != undefined ? formatThousands(x.gn2O) : ""}</td>
                                                                    <td>{x.kgCO2 != undefined ? formatThousands(x.kgCO2) : ""}</td>
                                                                    <td>{formatThousands(x.totalKgCO2e)}</td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </>}

                            </>
                        ))}
                        {(status === 'FINALIZED' || UserService.hasRole(['admin'])) && <tr>
                            {Object.keys(bloquePreguntas.answerHeaderOutputDto).map((key, index) => (
                                <td key={key} className="font-bold">

                                    {camposASumar.includes(key) ? (
                                        formatThousands(fieldSums[key])
                                    ) : index === 0 ? "Total" : ""}

                                </td>
                            ))}
                        </tr>}
                    </tbody>
                </table>
            </div>
            <div className="mt-1 mr-1">
                <p className="text-xs text-right"> Mostrando {currentItems.length} de {filteredBloquePreguntasLines.length}.</p>
            </div>
            {answers.isFetching &&
                <div className="flex  justify-center  items-center">
                    <p>Cargando respuestas...</p>
                    <Spinner animation="border" variant="dark" className="ml-2" size="sm" />
                </div>}

            {!answers.isFetching && filteredBloquePreguntasLines.length > 25 &&
                <div className="pagination felx gap-2 justify-center mt-2 items-center">
                    <button className="btn-sm btn-green text-xs"
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>
                    <span className="text-sm">Página <b>{currentPage}</b> de {Math.ceil(items.length / itemsPerPage)}</span>
                    <button className="btn-sm btn-green text-xs"
                        onClick={() => setCurrentPage((prev) => (indexOfLastItem < items.length ? prev + 1 : prev))}
                        disabled={indexOfLastItem >= items.length}
                    >
                        Siguiente
                    </button>
                </div>
            }
        </div>
    )

}

export default AnswerTable;